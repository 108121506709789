@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Knewave&family=Roboto+Slab:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gluten:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.robotofont {
  font-family: 'Roboto Slab', serif;
}

.title {
  font-family: 'Gluten', serif;
  font-size: 74px;
  color: #FFFFFF;
}

.subtitle {
  font-family: 'Gluten', serif;
  font-size: 35px;
  color: #FFFFFF;
}

.review-fontstyle{
  font-family: 'Gluten', serif;
  font-size: 20px;
  color: #ff686b;
}

.services-fontstyle{
  font-family: 'Gluten', serif;
  font-size: 20px;
  color: #84dcc6;
}

.about-fontstyle {
  font-family: 'Roboto', serif;
  font-size: 20px;
  color: #ffffff;
}

.reviewfont{
  color: #ff686b;
}
.bg-service {
	background-color: #ffa69e;
}

.bg-footer {
  background-color: #ff686b;
}

.bg-about {
  background-color: #84dcc6;
}

#title {
  width: 7ch;
  white-space: wrap;
}

#intro {
  padding: 0;
}

